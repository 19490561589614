






































import {
  AisConfigure,
  AisStateResults,
  AisSortBy,
  AisRangeInput,
  AisRatingMenu,
  AisPagination
} from 'vue-instantsearch';

import { defineComponent } from '@nuxtjs/composition-api';
import FilterModal from '~/components/organisms/Search/FilterModal.vue';
import SearchResultsInner from '~/components/organisms/Search/SearchResultsInner.vue';
import { useAlgolia } from '~/composables';
import { HITS_PER_PAGE_ITEMS, ALGOLIA_ATTRIBUTE, MAX_STARS } from '~/constants/algolia';
import { getAlgoliaSortingConfiguration } from '~/helpers/algolia/getAlgoliaSortingConfiguration';
import { getCurrentInstance } from '~/helpers/getCurrentInstance';

export default defineComponent({
  name: 'SearchResults',
  components: {
    FilterModal,
    AisConfigure,
    AisStateResults,
    AisSortBy,
    AisRangeInput,
    AisRatingMenu,
    AisPagination,
    SearchResultsInner
  },
  props: {
    categoryFilter: {
      type: String,
      default: ''
    }
  },
  setup() {
    const root = getCurrentInstance();
    const { $i18n } = root;

    const { hitsPerPageConfiguration } = useAlgolia();
    HITS_PER_PAGE_ITEMS.forEach(config => {
      config.default = config.value === hitsPerPageConfiguration.value;
    });

    return {
      sortingConfig: getAlgoliaSortingConfiguration($i18n),
      hitsPerPageConfiguration,
      ALGOLIA_ATTRIBUTE,
      MAX_STARS
    };
  }
});

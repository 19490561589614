
import config from '~/config';

export type VolumeDiscountType = { volume: number, discountPercentage: number };

export const VOLUME_DISCOUNTS: Readonly<Array<VolumeDiscountType>> = [
  { volume: config.itemsNeededForFirstDiscount, discountPercentage: config.firstDiscountPercentage },
  { volume: config.itemsNeededForSecondDiscount, discountPercentage: config.secondDiscountPercentage },
  { volume: config.itemsNeededForThirdDiscount, discountPercentage: config.thirdDiscountPercentage }
] as const;

export const VOLUME_DISCOUNT_MULTI_MARKET_INFORMATIONAL_PAGE_URL = '/volume-discount';

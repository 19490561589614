

































import { computed, defineComponent, onMounted, ref, useContext, ssrRef } from '@nuxtjs/composition-api';
import type { HitResultItem } from 'vue-instantsearch';
import { onSSR, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import type { PropType } from '@nuxtjs/composition-api';
import AlgoliaContainer from '~/components/organisms/Search/AlgoliaContainer.vue';
import { useAlgolia, useCategories, useMetaExtended, useSeoRedirection, usePageViewEvent } from '~/composables';
import PageTitle from '~/components/molecules/PageTitle.vue';
import { generateMetaTitleWithCTFormat } from '~/helpers/commercetools/meta/generateMetaTitle';
import MissingTranslationNotification from '~/components/molecules/Product/MissingTranslationNotification.vue';
import DisplayHtmlDangerously from '~/components/atoms/DisplayHtmlDangerously/DisplayHtmlDangerously.vue';
import { useI18n } from '~/composables/useI18n';
import BlogSlider from '~/components/molecules/Blog/BlogSlider.vue';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';
import { getCategoryNameFromFilter } from '~/helpers/category/getCategoryNameFromFilter';
import type { CategoryTree } from '~/constants/categoryTree';

export default defineComponent({
  name: 'SearchCategory',
  components: {
    AlgoliaContainer,
    MissingTranslationNotification,
    PageTitle,
    DisplayHtmlDangerously,
    BlogSlider,
    ErrorLayout: () => import(/* webpackChunkName: "ErrorLayout" */
      '~/layouts/error.vue')
  },
  props: {
    categorySlug: {
      type: String,
      required: true
    },
    categoryTree: {
      type: Object as PropType<CategoryTree>,
      required: true
    }
  },
  setup(props) {
    const { res } = useContext();
    const { callPageViewEvent } = usePageViewEvent();
    const {
      triggerViewCategoryEvent,
      searchWithDefaultParameters,
      getters: {
        currentCategorySlugAllLocales,
        currentCategoryMetaTitle,
        currentCategoryMetaDescription,
        currentCategorySEODescription,
        currentCategoryBlogPosts
      }
    } = useCategories();

    const { addTags } = useCache();
    const {
      languageAndCountry,
      isDefaultLanguageAndCountry,
      buildLanguagePrefixedUrlBasedOnActiveLanguage
    } = useI18n();
    const formattedCategorySEODescription = computed(() =>
      isDefaultLanguageAndCountry.value
        ? currentCategorySEODescription.value
        : currentCategorySEODescription.value?.replace(
          /href="\//g, `href="${buildLanguagePrefixedUrlBasedOnActiveLanguage('/')}`)
    );

    const { i18n } = useVSFContext();
    const { getAlgoliaCategoryFilter } = useAlgolia();
    const { seoRedirection } = useSeoRedirection();
    const algoliaCategoryFilter = sharedRef('', 'algolia-category-filter');
    const onProductListingShown = (products: HitResultItem[]) => {
      triggerViewCategoryEvent(products, props.categorySlug);
    };

    const activeCategory = computed(() => getCategoryNameFromFilter(algoliaCategoryFilter.value));

    const isNewCategoryTree = computed(() => !!props.categoryTree.prefix);

    const setAlgoliaCategoryFilter = async () => {
      await searchWithDefaultParameters(props.categorySlug, props.categoryTree);
      algoliaCategoryFilter.value = getAlgoliaCategoryFilter(props.categorySlug);
    };

    const error = ssrRef(null);

    onSSR(async () => {
      error.value = null;
      await setAlgoliaCategoryFilter();

      // check if valid category page
      if (algoliaCategoryFilter.value) {
        addTags([
          { prefix: CacheTagPrefix.View, value: 'searchcategory' },
          { prefix: CacheTagPrefix.Block, value: props.categorySlug }
        ]);
        return;
      }
      // check if a redirection exists for invalid slug
      // await is necessary here else we get error(Cannot set headers after they are sent to client)
      const redirectHappened = await seoRedirection();
      if (redirectHappened) {
        return;
      }

      // set error if no redirection exists for invalid slug
      const statusCode = STATUS_RESOURCE_NOT_FOUND;
      error.value = { statusCode };
      if (res) {
        res.statusCode = statusCode;
      }
    });

    const defaultMetaDescription = i18n.t(
      'SEO Category meta description', { categoryName: activeCategory.value }
    );
    useMetaExtended({
      metaTitle: computed(() =>
        currentCategoryMetaTitle.value ||
        generateMetaTitleWithCTFormat(activeCategory.value, i18n)
      ),
      isPageIndexed: ref(true),
      metaDescription: computed(() => currentCategoryMetaDescription.value || defaultMetaDescription),
      slug: ref(props.categorySlug),
      localisedSlugs: currentCategorySlugAllLocales
    });

    onMounted(() => {
      callPageViewEvent({
        pageCategory: isNewCategoryTree.value ? 'newCategoryTree' : 'category'
      });
    });

    return {
      algoliaCategoryFilter,
      onProductListingShown,
      activeCategory,
      formattedCategorySEODescription,
      currentCategoryMetaDescription,
      languageAndCountry,
      currentCategoryBlogPosts,
      error
    };
  },
  /** empty head needed to activate useMeta composable. */
  head: {}
});




























import { defineComponent } from '@nuxtjs/composition-api';
import VolumeDiscountRow from './VolumeDiscountRow.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import {
  VOLUME_DISCOUNTS
} from '~/constants/volumeDiscounts';
import volumeDiscountConfig from '~/config';

export default defineComponent({
  name: 'VolumeDiscount',
  components: {
    VolumeDiscountRow
  },
  props: {
    finalPrice: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      volumeDiscountList: VOLUME_DISCOUNTS,
      LinkType,
      volumeDiscountConfig
    };
  }
});











import {
  AisInstantSearchSsr
} from 'vue-instantsearch';
import { defineComponent } from '@nuxtjs/composition-api';
import { createServerRootMixin } from 'vue-instantsearch/src/instantsearch';
import { getAlgoliaClientWithAllFacets } from '@/helpers/algolia/algolia';
import { $cloneComponent, algoliaRouter } from '~/helpers/algolia/algoliaSSR/algoliaSSR';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import SearchResults from '~/components/organisms/Search/SearchResults.vue';
import { algoliaSSRRequiredFields } from '~/mixins/algoliaSSRRequiredFields';
import localizationDefinitions from '~/i18n/localizationDefinitions';
import { getAlgoliaIndexName } from '~/i18n/algoliaLocalization';

const isSSRMode = true;
// Component based on Algolia doc:
// https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/vue/#with-nuxt
export default defineComponent({
  name: 'AlgoliaContainer',
  components: {
    SearchResults,
    AisInstantSearchSsr
  },
  mixins: [algoliaSSRRequiredFields()],
  props: {
    categoryFilter: {
      type: String,
      default: ''
    },
    languageAndCountry: {
      type: String,
      default: null
    }
  },
  data() {
    const algoliaIndexName = getAlgoliaIndexName(this.$props.languageAndCountry, localizationDefinitions);
    const mixin = createServerRootMixin({
      searchClient: getAlgoliaClientWithAllFacets(this.$props.languageAndCountry),
      indexName: i18nToAlgoliaMainIndex(this.$i18n),
      $cloneComponent,
      routing: {
        router: algoliaRouter(this.$nuxt?.$router ?? this.$router, isSSRMode, algoliaIndexName)
      }
    });
    return {
      ...mixin.data()
    };
  }
});


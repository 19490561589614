




























import { defineComponent } from '@nuxtjs/composition-api';
import {
  AisStats,
  AisSearchBox
} from 'vue-instantsearch';
import CustomCurrentRefinements from '@/components/molecules/Search/CustomCurrentRefinements.vue';
import ClearRefinements from '@/components/atoms/Search/ClearRefinements.vue';
import { useAlgolia, useRouteExtended, useIsPage } from '~/composables';
import { ROUTES } from '~/constants/routes';

export default defineComponent({
  name: 'SearchFiltersHeader',
  components: {
    AisStats,
    ClearRefinements,
    AisSearchBox,
    CustomCurrentRefinements
  },
  setup(_props, { root }) {
    const { isFiltering } = useAlgolia();
    const { getAdjustedSlug } = useRouteExtended();
    const { isOnSearchPage } = useIsPage();
    const goToSearchPage = () => {
      root.$router.push(getAdjustedSlug(ROUTES.SEARCH));
    };
    return {
      isFiltering,
      isOnSearchPage,
      goToSearchPage
    };
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ais-current-refinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var createURL = ref.createURL;
return [_c('ul',{staticClass:"ais-CurrentRefinements-list"},_vm._l((items),function(item){return _c('li',{key:item.attribute,staticClass:"ais-CurrentRefinements-item flex flex-wrap"},_vm._l((item.refinements),function(refinement){return _c('CurrentRefinementLabel',{key:[
              refinement.attribute,
              refinement.type,
              refinement.value,
              refinement.operator
            ].join(':'),staticClass:"ais-CurrentRefinements-category\n              h-7 py-0.5 px-2 mr-2 mb-1\n            ",attrs:{"refinement":refinement,"create-url":createURL},on:{"refine":function($event){return item.refine($event)}}})}),1)}),0)]}}])}),_vm._v(" "),(_vm.isOnSearchPage)?_c('ais-hierarchical-menu',{staticClass:"mt-8",attrs:{"attributes":_vm.CATEGORIES_ATTRIBUTES,"sort-by":_vm.getCustomCategoryTreeOrder}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
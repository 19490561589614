
















import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'CheckBox',
  props: {
    isChecked: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const checkedIconSrc = '/searchpage/filter-checked.svg';
    const uncheckedIconSrc = '/searchpage/filter-unchecked.svg';

    const currentIconSrc = computed(() => props.isChecked ? checkedIconSrc : uncheckedIconSrc);
    return {
      currentIconSrc
    };
  }
});


































import { AisStats } from 'vue-instantsearch';
import { defineComponent, watch } from '@nuxtjs/composition-api';
import ClearRefinements from '@/components/atoms/Search/ClearRefinements.vue';
import { useUiState } from '~/composables';
import ModalHeading from '~/components/molecules/ModalHeading.vue';
import SearchFilters from '~/components/organisms/Search/SearchFilters/SearchFilters.vue';

export default defineComponent({
  name: 'FilterModal',
  components: {
    SearchFilters,
    ModalHeading,
    AisStats,
    ClearRefinements
  },
  setup() {
    const { isFilterModalOpen, toggleFilterModal } = useUiState();

    watch(isFilterModalOpen, (value) => {
      if (value) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    });

    return {
      isFilterModalOpen,
      toggleFilterModal
    };
  }
});

import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import {
  AWARE_PRODUCT_SKU_BEGINNING_CHARS,
  BWARE_PRODUCT_SKU_BEGINNING_CHARS,
  EXPONDO_SKU_PREFIX,
  PRODUCT_ATTRIBUTES
} from '~/constants/products';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { Product360Image } from '~/types/product/Product360Image';

export const getProduct360Image = (product: ProductVariant): Product360Image | null => {
  const numberOf360Images = getProductAttribute(product, PRODUCT_ATTRIBUTES.NUMBER_OF_360_IMAGES);
  const numberOfImages = parseInt(numberOf360Images) || 0;

  if (!numberOfImages || !product.sku) {
    return null;
  }

  const convertBWareToAWare = (sku: string) => {
    return sku.replace(BWARE_PRODUCT_SKU_BEGINNING_CHARS, AWARE_PRODUCT_SKU_BEGINNING_CHARS);
  };

  const removeSkuPrefix = (sku: string) => {
    return sku.replace(EXPONDO_SKU_PREFIX, '');
  };

  const productSku = removeSkuPrefix(convertBWareToAWare(product.sku));
  const path = `${productSku}_photo_360_{index}`;

  return {
    numberOfImages,
    path
  };
};












import { defineComponent } from '@nuxtjs/composition-api';
import SearchFiltersHeader from '~/components/organisms/Search/SearchFiltersHeader.vue';
import SearchFiltersBody from '~/components/organisms/Search/SearchFiltersBody.vue';
import SideCategories from '~/components/molecules/Category/SideCategories/SideCategories.vue';
import { useIsPage } from '~/composables';

export default defineComponent({
  name: 'SearchFilters',
  components: {
    SideCategories,
    SearchFiltersBody,
    SearchFiltersHeader
  },
  setup() {
    const { isOnSearchPage } = useIsPage();
    return {
      isOnSearchPage
    };
  }
});

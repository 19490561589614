




















































































import { defineComponent, computed, toRefs } from '@vue/composition-api';
import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { onSSR } from '@vue-storefront/core';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';
import {
  useProductShipping,
  useProductPrice,
  useStoreVsfExtended,
  useVolumeDiscounts,
  useProductAvailabilityDate,
  useRouteBestPriceGuarantee
} from '~/composables';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import ProductCardBadgeWrapper from '~/components/organisms/Product/ProductCardBadgeWrapper.vue';
import PriceDisclaimer from '~/components/molecules/Product/PriceDisclaimer.vue';
import AddToCart from '~/components/molecules/Product/AddToCart.vue';
import VolumeDiscount from '~/components/molecules/Product/VolumeDiscount/VolumeDiscounts.vue';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import VatInfo from '~/components/atoms/Product/VatInfo.vue';
import StockInformation from '~/components/molecules/Product/StockInformation.vue';
import LowestPriceGuaranteed from '~/components/atoms/Product/LowestPriceGuaranteed.vue';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import isString from '~/helpers/dataStructure/isString';
import { MAIN_ADD_TO_CART_ID } from '~/constants/floatingAddToCart';

export default defineComponent({
  name: 'ProductPricingSection',
  components: {
    AddToCart,
    ProductCardBadgeWrapper,
    LazyHydrate,
    PriceDisclaimer,
    VatInfo,
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue'),
    ProductSummaryDetails: () => import(/* webpackChunkName: "ProductSummaryDetails" */
      '~/components/organisms/Product/ProductSummaryDetails.vue'),
    VolumeDiscount,
    StockInformation,
    ProductCardDelivery: () => import(/* webpackChunkName: "ProductCardDelivery" */
      '~/components/atoms/Product/ProductCardDelivery.vue'),
    LowestPriceGuaranteed,
    LimitedTimeOffer: () => import(/* webpackChunkName: "LimitedTimeOffer" */
      '~/components/atoms/Product/LimitedTimeOffer.vue')
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true
    },
    productAdjustedForCart: {
      type: Object,
      required: true
    },
    availableQuantity: {
      type: Number,
      required: true
    },
    stock: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { product, attributesLocalized } = toRefs(props);

    const { getPriceInformation } = useProductPrice();
    const { isChangeTaxRateEnabled } = useStoreVsfExtended();

    const isProductReadyToShip = computed(() => props.availableQuantity > 0);

    const {
      isFreightFeeApplied,
      isParcelFeeApplied,
      shippingFeeDetails,
      deliveryDate
    } = useProductShipping(product);

    const {
      productPrice,
      finalPrice,
      priceBeforeDiscounts
    } = getPriceInformation(product, attributesLocalized);

    const { isActive: isVolumeDiscountActive, load: loadVolumeDiscountState } = useVolumeDiscounts();

    const releaseDate = computed(() =>
      productGetters.getAttributes(props.product, [PRODUCT_ATTRIBUTES.RELEASE_DATE])?.releaseDate
    );
    const testProductIdentifier = computed(() => isTestProduct(product.value.sku));
    const releaseDateString = computed(() => isString(releaseDate.value) ? releaseDate.value : '');

    const { calculateAvailabilityDate } = useProductAvailabilityDate(releaseDateString.value);
    const availabilityDate = computed(() => testProductIdentifier.value ? calculateAvailabilityDate() : '');

    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();

    const isInStock = computed(() => props.stock > 0);
    const showDeliveryTiming = computed(() => (deliveryDate.value && isInStock.value) || testProductIdentifier.value);
    const hasEnergyRating = computed(() => props.attributesLocalized?.energyRating?.rating);

    onSSR(async () => {
      await loadVolumeDiscountState();
    });

    return {
      SCHEMA_ITEM_TYPE,
      SCHEMA_ITEM_PROP,
      priceBeforeDiscounts,
      isFreightFeeApplied,
      isParcelFeeApplied,
      shippingFeeDetails,
      isProductReadyToShip,
      deliveryDate,
      productPrice,
      finalPrice,
      isChangeTaxRateEnabled,
      isVolumeDiscountActive,
      availabilityDate,
      isInStock,
      testProductIdentifier,
      showDeliveryTiming,
      priceGuaranteeUrl,
      hasEnergyRating,
      MAIN_ADD_TO_CART_ID
    };
  }
});
